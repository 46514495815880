footer.page-footer {
	.background > div > div strong {
		font-size: 125%;
	}

	.footercolor {
		color: $white;
	}

	.tealstyle {
		width: 60px;
		background-color: $blue;
	}

	p {
		margin-bottom: 0.25rem;
	}

	h6 {
		color: $blue;

	}

	/* Style all font awesome icons */
	.fa, .fab, .far, .fas {
		font-size: 30px;
		width: 50px;
		text-align: center;
		text-decoration: none;

		color: $white;

		&:hover {
			opacity: 0.7;
			color: $gray-300;
		}
	}

	.footer-language {
		color: $gray-600;
		padding-left: 100px;
	}

	.footer-copyright {
		border-top: solid;
		border-width: 1px;
		border-color: $blue;
		color: $gray-600;
	}

	.footer-bottom {
		font-size: 13px;
		margin-right: 13px;
		margin-left: 13px;
	}
}
