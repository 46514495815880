nav.navbar {
	border: 1px $blue;
	border-bottom-style: solid;

	.modal-content {
		-webkit-border-radius: 0 !important;
		-moz-border-radius: 0 !important;
		border-radius: 0 !important;
	}

	.user-notifications .dropdown-item {
		white-space: normal;
	}
}
