.form-check-input {
	margin-top: 0.15rem;
}

// Footer (for actions)
.form-footer {
	display: flex;
	align-items: center; // vertically center
	justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
	padding-top: map_get($spacers, 3);
	border-top: $modal-footer-border-width solid $modal-footer-border-color;

	&.no-flex {
		display: block;
		justify-content: flex-start;
	}

	// Easily place margin between footer elements
	> :not(:first-child) { margin-left: map_get($spacers, 1); }
	> :not(:last-child) { margin-right: map_get($spacers, 1); }
}

.row .form-check {
	padding-left: $grid-gutter-width / 2;

	.form-check-input {
		margin-left: 0;
	}

	.form-check-label {
		margin-left: $form-check-input-gutter;
	}
}
