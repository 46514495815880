.body-teamspeak-connectIdentity {
	form > #identity-selection {
		@extend .mx-sm-n1;
		margin-bottom: $spacer - $label-margin-bottom;
	}

	input[type=radio] {
		display: none;

		&:not(:checked) + label .icon-wrapper > .fa-user-check {
			display: none;
		}

		&:checked + label > div {
			background-color: $primary;
			color: $white;

			.icon-wrapper > .fa-user {
				display: none;
			}
		}

		& + label {
			@extend .col-sm-4;
			@extend .px-sm-1;

			> div {
				> .card-body > div {
					@extend .d-flex;
					@extend .flex-row;

					> div {
						@extend .d-flex;
						@extend .align-items-center;
					}
				}

				.icon-wrapper > .fa {
					font-size: 2.25em;
					width: 1.75em;
				}
			}
		}
	}
}
