#cookie-banner {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;

	padding: (2 * $spacer) 0;

	background-color: $light;
	border-top: 1px solid $blue;

	.container {
		display: flex;
		align-items: center;
		justify-content: center;

		.fa {
			font-size: 2.5 * $font-size-base;
		}

		div {
			margin: 0 $spacer;
			text-align: center;
		}
	}
}
