#profileWrapper {
	padding-top: map_get($spacers, 3);
	padding-bottom: map_get($spacers, 3);

	#profile {
		padding-top: map_get($spacers, 3);
		padding-bottom: map_get($spacers, 3);

		background-color: $card-bg;

		> header {

			.userInfo {
				display: flex;
				flex-wrap: nowrap;

				> img {
					width: 144px;
					height: 144px;
					margin-right: map_get($spacers, 3);
				}

				h1:first-child {
					line-height: 0.85;
					margin-bottom: map_get($spacers, 3);
					margin-right: map_get($spacers, 3);

					> small {
						font-size: 50%;
					}
				}

				.badge {
					font-size: 100%;
				}
			}

			aside {
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				flex-direction: column;
			}
		}

		section.comments {
			> h2 > .badge {
				font-size: 60%;
			}
		}
	}
}
