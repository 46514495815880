.body-index {
	.banner {
		text-align: center;
		padding-top: 5px;
		height: 770px;
		background: url('/img/banner.jpg') no-repeat;
		background-position-x: center;

		@media (min-width: map_get($grid-breakpoints, md)) {
			.display {
				font-size: 6rem;
			}
		}

		p {
			margin: 0;
		}

		.content {
			color: $white;
			padding: 1rem;
		}
	}

	.sponsor {
		background-color: $blue;
		color: $white;

		padding: 3rem;
		font-size: 125%;

		img {
			height: 3rem;
		}
	}

	.main > article {
		@media (min-width: map_get($grid-breakpoints, md)) {
			font-size: 125%;

			h2 {
				font-size: 125%;
				margin-bottom: 2rem;
			}
		}

		h2 {
			margin-bottom: 2rem;
		}

		&:nth-child(odd) {
			flex-direction: row-reverse;
		}

		&:nth-child(4n+0) > section {
			color: $white;
			background-color: #5f5f5f;
		}

		&:nth-child(4n+1) > section {
			background-color: $white;
		}

		&:nth-child(4n+2) > section {
			color: $white;
			background-color: #157CB7;
		}

		&:nth-child(4n+3) > section {
			background-color: $white;
		}

		> section {
			padding: 3em;
		}
	}
}
