.viout-background-image {
	background-image: url('https://us-wotp.wgcdn.co/static/5.20.0_770946/wotp_static/img/core/frontend/scss/common/blocks/video-bg/img/poster.jpg');
	background-position-y: 30%;

	padding-top: map_get($spacers, 3);
	padding-bottom: map_get($spacers, 3);

	.card {
		color: $white;
		background-color: rgba(0, 0, 0, 0);

		.card-header {
			border-radius: 0;
			background-color: rgba(0, 0, 0, 0.7);
		}

		.card-body {
			background-color: rgba(0, 0, 0, 0.6);
		}
	}

	.form-footer {
		border-color: $gray-600;
	}
}
