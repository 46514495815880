.body-rankComparison {
	.comparison-table {
		width: 100%;
		display: flex;
		flex-direction: row;
		text-align: center;
	}

	.comparison-plan-container {
		width: 100%;
		display: flex;
		flex-direction: column;

		@include media-breakpoint-up('lg') {
			flex-direction: row;
		}

		position: relative;

		.backdrop {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: -999;

			@include media-breakpoint-up('lg') {
				box-shadow: $join-box-shadow;
			}
		}

		.backdrop, .comparison-plan {
			margin: $spacer 0;

			border-radius: $border-radius-sm;
		}

		.comparison-plan {
			padding: $spacer;

			box-shadow: $join-box-shadow;
			background-color: $light;

			@include media-breakpoint-up('lg') {
				width: 33%;
				box-shadow: none;
			}

			h2 {
				text-transform: uppercase;
			}

			dl {
				position: relative;
				display: flex;
				flex-direction: row-reverse;
				flex-wrap: wrap;

				dt, dd {
					margin: 0 ($spacer * .5);
					flex-grow: 1;
				}

				dt {
					text-align: left;
					font-weight: lighter;

					width: calc(70% - #{$spacer});
				}

				dd {
					text-align: right;
					font-weight: bold;

					width: calc(30% - #{$spacer});
				}
			}

			p {
				font-size: $font-size-lg;
			}

			.alert {
				margin: 0;
			}
		}

		.comparison-plan-recommended {
			overflow: hidden;
			position: relative;

			@include media-breakpoint-up('lg') {
				padding: (2 * $spacer) (1 * $spacer);
				margin: 0;
			}

			box-shadow: $join-box-shadow-lg;
			border-radius: $border-radius-lg;

			.banner {
				position: absolute;
				top: 1rem;
				left: -2rem;
				transform: rotate(-45deg);
				color: white;
				background-color: $blue;
				width: 8rem;
			}
		}
	}
}
