img {
	&.user-icon, &.user-icon-sm, &.user-icon-lg {
		@extend .rounded-circle;
	}

	&.user-icon {
		width: 3.375rem;
		height: 3.375rem;
	}

	&.user-icon-sm {
		width: 2.25rem;
		height: 2.25rem;
	}

	&.user-icon-lg {
		width: 5rem;
		height: 5rem;
	}
}
