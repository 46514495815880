// Fonts
//@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

@import "fonts";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

@import 'layout/backgroundImage';
@import "layout/cookie-banner";
@import "layout/header";
@import "layout/footer";

@import "layout/form";
@import "layout/userIcon";

@import 'toastNotifications';

@import "pages/index";
@import "pages/profile";
@import "pages/rankComparison";
@import "pages/register";
@import "pages/teamspeak/connectIdentity";

.compact-text {
	line-height: 0.875 * $line-height-base;
}

body > .container, body > .container-fluid {
	margin-top: map_get($spacers, 3);
	margin-bottom: map_get($spacers, 3);
}

.message {
	overflow: hidden;
	word-wrap: break-word;
}

.glass {
	border: none;
	border-radius: 0;
	background: inherit;
	z-index: 0;

	&:after {
		border-radius: $border-radius;
		filter: blur(8px);
		background: inherit;
		background-position-x: -25vw;

		content: '';
		height: 100%;
		width: 100%;

		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.background {
	background-color: $blue;
}

ul.pagination {
	margin-bottom: 0;
}

blockquote {
	background-color: $gray-100;
	border-left: map_get($spacers, 1) solid $gray-300;
	padding: map_get($spacers, 2) map_get($spacers, 3);
}

a.text {
	color: $black !important;
	text-decoration: none !important;
}
