// Body
$body-bg: #f5f8fa;

// Typography
$font-family-sans-serif: "Courier New", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Borders
$border-radius: 1.5 * $font-size-base;
$border-radius-lg: 1.2 * $border-radius;
$border-radius-sm: 0.8 * $border-radius;
$dropdown-border-radius: 0;

// Buttons
$input-btn-focus-width: 0;
$blue: #157CB7;

// Join Box Shadow
$join-box-shadow: 0 0 1rem rgba(0, 0, 0, .15);
$join-box-shadow-lg: 0 0 1rem rgba(0, 0, 0, .2);
