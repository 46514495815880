#toast-panel {
	position: fixed;

	bottom: 0;
	right: 0;

	@include media-breakpoint-up('md') {
		bottom: map_get($spacers, 3);
		right: map_get($spacers, 3);
	}

	.toast-item {
		.toast-header {
			display: flex;
			justify-content: space-between;

			.toast-title {
				margin-bottom: 0;

				margin-right: map_get($spacers, 4);
				line-height: $line-height-base;
			}
		}

		margin-bottom: map_get($spacers, 3);
		padding: map_get($spacers, 3);
		background-color: rgba($dark, 0.85);
		color: $light;
	}
}
